import { api } from '@/services/modules/ThirdParty/opportunity';
import { SCHEDULE_REPORTS_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ThirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

export async function getScheduleReports(
  params = new QueryParamsBuilder(),
  config = { preventKey: 'get_schedule_reports' }
) {
  const partialUrl = api.createUrl({
    [SCHEDULE_REPORTS_RESOURCE]: undefined,
  });

  const { data } = await api.get(partialUrl, params.build(), config);

  return new ResponseBuilder(ThirdPartyEntityParser.parse(data), data.meta).build();
}
