import { getOpportunities } from './index';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { dateToYYYYMMDD } from '@/filters/dateFilters';

/**
 *
 * @param params
 * @returns {Promise<*>}
 */
export async function getOpportunityByEndDate(params = new QueryParamsBuilder(1, 10000)) {
  params.removeFilter('product.start');
  params.addFilter('product.end', dateToYYYYMMDD(params.removeFilter('product.end')));
  return await getOpportunities(params);
}
