import { api } from './index';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { OPPORTUNITIES_RESOURCE } from '@/services/keys';

/**
 *
 * @param params
 * @param config
 * @returns {Promise<*>}
 */
export async function getOpportunities(
  params = new QueryParamsBuilder(1, 10000),
  config = { preventKey: 'opportunities' }
) {
  const partialUrl = api.createUrl({
    [OPPORTUNITIES_RESOURCE]: undefined,
  });

  const { data } = await api.get(partialUrl, params.build(), config);

  return data.data;
}
