import { getOpportunities } from './index';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { dateToYYYYMMDD } from '@/filters/dateFilters';

/**
 *
 * @param params
 * @returns {Promise<*>}
 */
export async function getOpportunityStarting(params = new QueryParamsBuilder(1, 10000)) {
  params.addFilter('product.start', dateToYYYYMMDD(params.removeFilter('product.start')));
  params.removeFilter('product.end');

  return await getOpportunities(params);
}
